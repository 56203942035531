import TokenHandler from "./component/TokenHandler";
import useToken from "./hooks/useToken";
import CodeList from "./component/CodeList";
import MetricList from "./component/MetricList";

const styles = {
  body: {
  }
}

function App() {
  const [token] = useToken();
  // get uri location
  const path = window.location.pathname.substring(1);
  let customerId = undefined;
  if (path !== 'admin') {
    customerId = path;
  }
  return (
    <div style={styles.body}>
      <TokenHandler />
      { token ? <CodeList customerId={customerId} /> : null}
      { path === 'admin' && token ? <MetricList /> : null}
    </div>
  );
}

export default App;

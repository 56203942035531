import React, {useState} from 'react';
import moment from 'moment';
import useRequest from "../hooks/useRequest";
import '../table.css';
import CodeDetail from "./CodeDetail";

const styles = {
  container: {
    backgroundColor: '#fff',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    margin: 16,
    width: 'calc(100% - 32px)',
    position: 'relative',
    height: 400,
    overflowY: 'scroll'
  },
  reload: {
    position: 'absolute',
    top: 12,
    right: 16,
  },
  loading: {
    textAlign: 'center',
  },
  error: {
    textAlign: 'center',
    color: 'red'
  }
}

const resultMapper = (codeList) => ({
  codeList,
  codeById: codeList.reduce((acc, code) => ({ ...acc, [code._id]: code }), {})
});

const CodeList = (props) => {
  const customerId = props.customerId;
  const url = typeof customerId === 'string' ? `watermark?customerId=${customerId}` : 'watermark';
  const [requestState, reload] = useRequest({
    url,
    method: 'get',
    resultMapper
  });
  const [editingCodeId, setEditingCodeId] = useState();
  const { loading, loaded, result, error } = requestState;
  const { codeList = [], codeById = {} } = result || {};
  return (
    <>
      <div style={styles.container}>
        {loading ? <p style={styles.loading}>loading...</p> : (
          <p style={styles.error}>{error}{'.'}</p>
        )}
        <button type="button" style={styles.reload} onClick={reload}>Reload</button>
        {loaded ? (
          <table>
            <thead>
              <tr>
                <th>_id</th>
                <th>CustomerId</th>
                <th>Code</th>
                {/* <th>CodeInt</th> */}
                <th>Title</th>
                <th>Url</th>
                <th>Created</th>
                <th>Updated</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            { codeList.length === 0 ? (
              <tr>
                <td colSpan={8}>
                  No Codes Yet.
                </td>
              </tr>
            ) : null}
              {
                codeList.map(({ _id, customerId, code, title, url, createdAt, updatedAt }) => (
                  <tr key={code}>
                    <td>{_id}</td>
                    <td>{customerId}</td>
                    <td>{code}</td>
                    {/* <td>{(() => {
                      let x = code.split('.');
                      x = x[x.length - 1];
                      x = parseInt(x, 16);
                      return x;
                    })()}</td> */}
                    <td>{title}</td>
                    <td><a href={url} target="_blank" rel="noreferrer noopener nofollower">{url}</a></td>
                    <td>{moment(createdAt).format('lll')}</td>
                    <td>{moment(updatedAt).format('lll')}</td>
                    <td><button type="button" onClick={() => setEditingCodeId(_id)}>Edit</button></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        ) : null}
      </div>
      { editingCodeId ? (
        <CodeDetail {...codeById[editingCodeId]} reload={reload} />
      ) : null}
    </>
  )
}

export default CodeList;

import React, {useCallback, useEffect, useState} from "react";
import useRequest from "../hooks/useRequest";

const styles = {
  form: {
    backgroundColor: '#fff',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyAlign: 'center',
    alignItems: 'center',
    padding: 20,
    margin: 16,
    width: 'calc(100% - 32px)',
  },
  heading: {
    marginTop: 0,
  },
  error: {
    color: 'red'
  }
}

const CodeDetail = (props) => {
  const [updateBody, setUpdateBody] = useState({});
  useEffect(() => {
    setUpdateBody({ code: props.code, title: props.title, url: props.url });
  }, [props.code, props.title, props.url]);
  const url = `watermark?customerId=${props.customerId}`;
  const [updateState, updateReload] = useRequest({
    url,
    method: 'put',
    body: updateBody,
    enabled: false
  });

  const handleChange = useCallback((e) => {
    setUpdateBody(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  }, [setUpdateBody])

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const success = await updateReload(true);
    if (success) {
      props.reload();
    }
  }, [props.reload, updateReload]);

  const { loading, error } = updateState;

  return (
    <form style={styles.form} onSubmit={handleSubmit}>
      {loading ? <p>updating...</p> : (
        <p style={styles.error}>{error}{'.'}</p>
      )}
      <h3 style={styles.heading}>{props.code} ({(() => {
        let x = props.code.split('.');
        x = x[x.length - 1];
        x = parseInt(x, 16);
        return x;
      })()})</h3>
      <div>
        <label><b>Title</b></label>
        {' '}
        <input type="text" value={updateBody.title} name="title" onChange={handleChange}></input>
      </div>
      <div>
        <label><b>URL</b></label>
        {' '}
        <input type="url" value={updateBody.url} name="url" onChange={handleChange}></input>
      </div>
      <div>
        <input type="submit" value="Save Changes"></input>
      </div>
    </form>
  )
}

export default CodeDetail;
